<template>
  <div class="w-100">
    <header-menu :register="true" @open-register="data.registerVisible=true"></header-menu>
    <div class="flex w-100 center-xs mt-30 mb-30">
      <login-template @open-register="data.registerVisible = true"></login-template>
    </div>
    <register-popup v-if="data.registerVisible" @close="data.registerVisible = false"></register-popup>
    <div class="global-blue flex col ac-c center-xs pt-20" >
      <h2 class="mt-30 mb-15">Hall of Fame</h2>
      <h3 class="mb-30">Top device based on the average Strike score</h3>
      <result-list class="w-100 mb-30 pb-30 col-xs-10" path="results/top"></result-list>
    </div>
    <footer-menu></footer-menu>
  </div>
</template>
<script setup>
import { RegisterPopup, HeaderMenu, FooterMenu } from "@/components"
import LoginTemplate from '../components/LoginTemplate.vue'
import ResultList from '../components/ResultList.vue'
import {reactive} from "vue";

const data = reactive({
  registerVisible: false
})
</script>